import './BookingsContent.less'

import classNames from 'classnames'
import dayjs from 'dayjs'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AclFeature,
  Booking,
  BookingFilterOptions,
  Feature,
  fetchBookings as fetchBookingsRequest
} from '../../../requests'
import { routes } from '../../../routes'
import { LocalStorageKey } from '../../../utils'
import { RedirectNoAccessWrapper } from '../../RedirectNoAccessWrapper'
import { UserContext } from '../../auth'
import { DatacIcon, DatacLoading, DatacMessage, DatacPagination, DatacTitle } from '../../common'
import { CalendarLayoutHeader } from '../CalendarLayout/CalendarLayoutHeader'
import { CalendarLayoutSidebar } from '../CalendarLayout/CalendarLayoutSidebar'
import { useCalendarStore } from '../CalendarStore'
import { BookingFilters } from './BookingFilters'
import { BookingRecord } from './BookingRecord'

const pageSize = 50

export const BookingsContent: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlCalendar = useScopedIntl('calendar')
  const intlBookings = useScopedIntl('calendar.bookings')
  const {
    setCurrentView,
    isSidebarVisible,
    requestDates,
    currentView,
    currentDate,
    setCurrentDate,
    goToNext,
    goToPrevious
  } = useCalendarStore()
  const { user } = useContext(UserContext)
  const [isRequestDatesValid, setIsRequestDatesValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [bookings, setBookings] = useState<Booking[]>([])
  const [allBookingsCount, setAllBookingsCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState<string>()
  const [filter, setFilter] = useState<BookingFilterOptions>(BookingFilterOptions.ALL)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    setCurrentView('bookings')
  }, [])

  const isToday = useMemo(() => {
    return dayjs().isSame(currentDate, 'day')
  }, [currentDate])

  useEffect(() => {
    fetchBookings(0)
  }, [requestDates, search, filter, currentPageSize, isRequestDatesValid])

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.LastFeature, Feature.Bookings)
    setCurrentDate(currentDate, currentView === 'day' ? undefined : true)
    setIsRequestDatesValid(true)
  }, [])

  const fetchBookings = (page: number) => {
    if (!isRequestDatesValid) return
    setIsLoading(true)
    fetchBookingsRequest(
      {
        ...requestDates,
        options: {
          limit: currentPageSize,
          offset: page * currentPageSize,
          search,
          ...(isToday ? { filter } : {})
        }
      },
      {
        onSuccess: ({ bookings, allBookingsCount }) => {
          setIsLoading(false)
          setBookings(bookings)
          setAllBookingsCount(allBookingsCount)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsLoading(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onSearchChange = (value: string) => {
    setSearch(value)
  }

  const onFiltersChange = (filter: BookingFilterOptions) => {
    setFilter(filter)
  }

  const onPageChange = (page: number) => {
    fetchBookings(page - 1)
  }

  const paginationConfig = {}

  return (
    <RedirectNoAccessWrapper
      hasNoAccess={!user.isCalendarEnabled || !user.canAccess(AclFeature.Calendar)}
      path="bookings"
    >
      <div className={classNames('bookings', !isSidebarVisible && 'bookings--minimized')}>
        <CalendarLayoutHeader />
        {isSidebarVisible && <CalendarLayoutSidebar />}
        <div className="bookings__header">
          <div onClick={() => navigate(routes.calendar)} className="bookings__header__go-back">
            <DatacIcon className="bookings__header__go-back-icon" name="arrowLeft" type="blue" size="small" />
            {intl('common.back')}
          </div>
          <div>
            <DatacTitle type="h2">{intlBookings('title')}</DatacTitle>
          </div>
        </div>
        <div className="bookings__body">
          <div className="bookings__body__toolbar">
            <div className="bookings__body__toolbar__controls">
              <button
                type="button"
                className="bookings__body__toolbar__today"
                onClick={() => setCurrentDate(dayjs(), true)}
              >
                {intlCalendar('today')}
              </button>
              <button type="button" onClick={() => goToPrevious(true)}>
                <DatacIcon name="chevronLeft" />
              </button>
              <button type="button" onClick={() => goToNext(true)}>
                <DatacIcon name="chevronRight" />
              </button>
              <div className="bookings__body__toolbar__date">{currentDate.format('dddd, MMMM DD, YYYY')}</div>
            </div>

            <div className="bookings__body__toolbar__tags">
              <div className="bookings__body__toolbar__tags__tag bookings__body__toolbar__tags__tag--total">
                {`${allBookingsCount} ${
                  allBookingsCount === 1 ? intlBookings('bookings_count_one') : intlBookings('bookings_count')
                }`}
              </div>
            </div>
            <BookingFilters
              onSearchChange={value => onSearchChange(value)}
              onFilterChange={onFiltersChange}
              isSearching={isLoading}
              initialSearchValue={search}
              initialFilterValue={filter}
              showFilters={isToday}
            />
          </div>
          <div className="bookings__body__list">
            <DatacLoading isLoading={isLoading}>
              {bookings.map(booking => (
                <BookingRecord booking={booking} key={booking.id} />
              ))}
            </DatacLoading>
          </div>
        </div>
        {paginationConfig && (
          <DatacPagination
            current={currentPage}
            onChange={onPageChange}
            total={allBookingsCount}
            pageSize={currentPageSize}
            setPageSize={setCurrentPageSize}
            isDetached
          />
        )}
      </div>
    </RedirectNoAccessWrapper>
  )
}
